.servicesSectionTitles {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 22px;
  margin-top: 32px;
}

.servicesSectionTitles p {
  background-color: var(--primary-color);
  border-radius: 15px;
  padding: 10px 25px;
  color: white;
  font-family: sans-serif;
}

.servicesSectionTitles h2 {
  font-family: sans-serif;
  font-size: 46px;
}

.flexBoxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-items: center;
  gap: 50px;
  margin: 50px;
}

.flexBoxes article {
  background-color: rgb(219, 219, 219);
  width: 300px;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  gap: 10px;
  cursor: pointer;
  border-radius: 10px;
}

.flexBoxes article:hover {
  transform: scale(101%);
  -webkit-box-shadow: 10px 10px 19px -5px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 10px 10px 19px -5px rgba(0, 0, 0, 0.33);
  box-shadow: 10px 10px 19px -5px rgba(0, 0, 0, 0.33);
  border: 3px solid var(--primary-color);
  transition: 0.2s all ease;
}

.flexBoxes article h3 {
  font-family: sans-serif;
  font-size: 32px;
  text-align: center;
}

.flexBoxes article:hover h3 {
  color: var(--primary-color);
}

.flexBoxes article p {
  font-family: sans-serif;
  font-size: 14px;
  text-align: center;
}

.selectedService {
  display: flex;
  gap: 20px;
  margin: 100px 100px 50px;
  flex-direction: column-reverse;
}

.imageSelectedService {
  width: 450px;
  max-height: 600px;
  margin: 5px;
}

.selectedServiceDetail {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.selectedServiceDetail h3 {
  font-family: sans-serif;
  font-size: 46px;
  text-align: center;
}

.selectedServiceDetailTexts p {
  font-family: sans-serif;
  margin-bottom: 30px;
  font-size: 18px;
}

.imagesSelected{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
}

@media (max-width: 1200px) {
  .selectedService {
    gap: unset;
    margin: 80px 40px;
  }
  .imageSelectedService {
    width: 100%;
    height: unset;
  }
  .selectedServiceDetail h3 {
    font-size: 40px;
  }
}

@media (max-width: 620px) {
  .flexBoxes {
    margin: unset;
    margin-top: 40px;
  }
  .sevicesSection {
    margin: 0 0 60px 0;
  }
  .imagesSelected{
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}