.footerSection{
  background-color: var(--primary-color);
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.contactIconsFooter{
  display: flex;
  width: 100%;
  gap: 4%;
  color: white;
  font-family: sans-serif;
  justify-content: center;
}

.contactIconsFooter i {
  cursor: pointer;
  }

.contactInfoFooter{
  display: flex;
  gap: 10%;
  white-space: nowrap;
  color: white;
  font-family: sans-serif;
}

@media (max-width: 620px) {
  .contactInfoFooter {
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
}