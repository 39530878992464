.navbarContact {
  width: 100%;
  height: 40px;
  background-color: var(--primary-color);
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 100px;
}

.contactInfo {
  gap: 10%;
  display: flex;
  justify-content: space-between;
  color: white;
  background-color: var(--primary-color);
}

.contactInfo p {
  white-space: nowrap;
  font-family: sans-serif;
}

.navbar {
  display: flex;
  padding: 15px 10% 0 10%;
  width: 100%;
  height: 80px;
  gap: 10%;
  z-index: 1;
}

.navbarLinks {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.navbarLinks li {
  list-style: none;
  width: 100%;
  height: 100%;
}

.navbarLinks a {
  text-decoration: none;
  color: white;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navbarLinks p {
  text-decoration: none;
  color: white;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.navbarLinks li:hover {
  background-color: rgba(128, 128, 128, 0.2);
}

.menuButton {
  align-items: center;
  display: none;
}

.menuLinks.open {
  display: flex;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -1;
  background: rgb(0 0 0 / 70%);
  backdrop-filter: blur(10px);
}

.menuLinks {
  display: none;
}

.menuLinks ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.menuLinks li {
  list-style: none;
  width: 100%;
  height: 100%;
}

.menuLinks a {
  text-decoration: none;
  color: white;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;
}

.menuLinks p {
  text-decoration: none;
  color: white;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;
}

.menuLinks li:hover {
  background-color: rgba(128, 128, 128, 0.2);
}

.menuContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
}

.contentHome {
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 10%;
  gap: 25px;
}

.contentHome h1 {
  color: white;
  font-size: 7vw;
  font-family: sans-serif;
}

.contentHome p {
  color: white;
  font-size: 20px;
  width: 40%;
  font-family: sans-serif;
}

.backgroundHome {
  background-image: url(../../assets/img/background.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
}

.homeButtons {
  display: flex;
  gap: 5%;
}

.buttonViewServices {
  color: white;
  background-color: var(--primary-color);
  width: 200px;
  height: 60px;
  white-space: nowrap;
  font-size: 22px;
  transition: 0.5s all ease;
  font-family: sans-serif;
  cursor: pointer;
  border: 2px solid var(--primary-color);
}

.buttonViewServices:hover {
  background-color: transparent;
  transition: 0.5s all ease;
}

.buttonUs {
  color: white;
  background-color: transparent;
  border: 2px solid white;
  width: 200px;
  height: 60px;
  font-size: 22px;
  transition: 0.5s all ease;
  font-family: sans-serif;
  cursor: pointer;
}

.buttonUs:hover {
  background-color: var(--primary-color);
  transition: 0.5s all ease;
}

.usSection {
  display: flex;
  height: 100vh;
  width: 100%;
}

.leftUsSection {
  width: 50%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 96px;
}

.usSectionTitle {
  margin-bottom: 32px;
}

.titleTriangle {
  position: absolute;
  left: 0;
  border-style: solid;
  border-width: 45px 45px 45px 45px;
  border-color: transparent transparent transparent var(--primary-color);
}

.usSectionTitle h2 {
  color: var(--primary-color);
  font-size: 60px;
  font-family: sans-serif;
  display: flex;
  align-items: center;
}

.usSectionTitle p {
  font-size: 22px;
  font-family: sans-serif;
  font-weight: bold;
}

.usSectionParagraphs p {
  font-size: 18px;
  font-family: sans-serif;
  font-weight: 600;
  margin: 26px 0;
}

.rightUsSection {
  width: 50%;
  background-image: url(../../assets/img/usbackground.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}

.sevicesSection {
  margin: 60px 50px;
}

@media (max-width: 1200px) {
  .menuButton {
    display: flex;
  }
  .navbarLinks {
    display: none;
  }
  .navbarContact {
    display: none;
  }
  .contactInfo {
    gap: 5%;
    width: 100vw;
    margin-top: 20px;
  }
  .contentHome {
    height: 100vh;
  }
  .navbar {
    position: absolute;
    display: flex;
    justify-content: space-between;
  }
  .rightUsSection {
    display: none;
  }
  .leftUsSection {
    width: unset;
  }
  .usSection {
    height: 100vh;
  }
}

@media (max-width: 620px) {
  .leftUsSection {
    padding: unset;
  }
  .usSectionParagraphs {
    padding: 0 15%;
    height: unset;
  }
  .usSectionTitle {
    padding: 0 15%;
  }
  .titleTriangle {
    border-width: 25px 25px 25px 25px;
  }
  .usSectionTitle h2 {
    font-size: 40px;
  }
  .usSectionTitle p {
    font-size: 18px;
  }
  .buttonViewServices {
    width: 180px;
    height: 50px;
    font-size: 20px;
  }
  .contentHome p {
    font-size: 12px;
    width: 90%;
  }
  .contentHome h1 {
    font-size: 60px;
  }
}
